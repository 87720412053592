import React from "react"
import {GetTitle} from "../../Routes/AdminRoute";
import moment from "moment-timezone";
import {Button, Col, Row, Spinner, Table} from "reactstrap";
import OrderStatus from "../../Elements/OrderStatus";
import {matchPath} from "react-router";
import OrderModel from "../../Models/OrderModel";
import BoxedWidget from "../../Components/BoxedWidget";
import ShowPrice from "../../Elements/ShowPrice";
import {ApiErrorThrow, dateonly_format, datetime_format, defaultSnackbarOptions} from "../../Config";
import InvoiceDetails from "./InvoiceDetails";
import OrderPdfButton from "../../Components/OrderPdfButton";
import {withSnackbar} from "react-simple-snackbar";
import {Link} from "react-router-dom";
import TransactionModel from "../../Models/TransactionModel";
import TransactionPdfButton from "../../Components/TransactionPdfButton";
import {getBankObject} from "../../Banks";
import {react_intl} from "../../index";
import {FormattedMessage} from "react-intl";

const getParams = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/cp/orders/:orderId`,
    });
    return (matchProfile && matchProfile.params) || {};
};
const getParamsNoAuth = (pathname) => {
    const matchProfile = matchPath(pathname, {
        path: `/show-invoice/:orderId/:token`,
    });
    return (matchProfile && matchProfile.params) || {};
};


class OrderSingle extends React.Component {
    orderId = null
    token = ""

    state = {
        orderNumFilter: "",
        orderDetails: {},
        payments: [],
        payments_loading: true
    }

    loadPayments = () => {
        TransactionModel.getOrderTrans(this.orderId).then(r => r.json())
            .then(r => {
                this.setState({
                    payments: r.data,
                    payments_loading: false
                })
            })
    }

    constructor(props) {
        super(props);
        let params

        if (typeof props.auth === "boolean" && props.auth === false)
            params = getParamsNoAuth(props.location.pathname)
        else
            params = getParams(props.location.pathname)

        this.orderId = params.orderId
        this.token = params.token
    }

    loadOrderDetails = () => {
        let req
        if (this.token)
            req = OrderModel.getByIdWithToken(this.orderId, this.token, ["currency"])
        else
            req = OrderModel.getById(this.orderId, ["currency"])

        req.then(r => r.json())
            .then(data => {
                let _data = data.data.attributes
                _data['currency'] = data.included.currencies[0]
                this.setState({
                    orderDetails: _data
                })
            })
    }

    componentDidMount() {
        document.title = GetTitle(react_intl.formatMessage({id: "client.order_single.invoices_title"}))
        this.loadOrderDetails()
        if (!this.token)
            this.loadPayments()
    }

    render() {
        let {orderDetails} = this.state
        let currency = orderDetails.currency
        let {openSnackbar} = this.props

        if (typeof currency === "object" && typeof currency.data)
            currency = currency.data

        if (Object.keys(orderDetails).length === 0)
            return <div className={"text-center margin-top-100"}>
                <Spinner color={"dark"}/>
            </div>

        return <div className={"w-100 pl-3 pr-3 pt-3"}>
            <Row>
                <Col md={this.token ? 12 : 8}>
                    <BoxedWidget
                        title={react_intl.formatMessage({id: "client.order_single.details"})}
                        content={<>
                            <div>
                                <Row>
                                    <Col sm={6}>
                                        <h2><FormattedMessage id={"client.order_single.invoice_number"}/></h2>
                                        <p>#{orderDetails.order_num}</p>
                                    </Col>
                                    <Col sm={6} className={"text-right"}>
                                        <h3><FormattedMessage id={"client.order_single.invoice_status"}/></h3>
                                        <OrderStatus status={orderDetails.status}/>
                                        <p>&nbsp;</p>
                                        <h3><FormattedMessage id={"client.order_single.invoice_date"}/></h3>
                                        <p>{moment(orderDetails.created).tz("tr").format("DD/MM/YYYY HH:mm")}</p>
                                    </Col>
                                    <Col sm={12}>
                                        <hr/>
                                        {orderDetails.notes ? <>
                                            <h3><FormattedMessage id={"client.order_single.note"}/></h3>
                                            <p style={{fontSize: 18}}>{orderDetails.notes}</p>
                                            <hr/>
                                        </> : ""}
                                    </Col>
                                    <Col sm={12} className={"text-right"}>
                                        <h3><FormattedMessage id={"client.order_single.amount"}/></h3>
                                        <ShowPrice
                                            style={{fontSize: 36, fontWeight: 400}}
                                            amount={orderDetails.amount}
                                            currencyprefix={currency.prefix}
                                            currencysuffix={currency.suffix}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </>}
                    />
                    {this.token ? "" : this.state.payments_loading ?
                        <Spinner size={"sm"} color={"dark"}/> : this.state.payments.length > 0 ?
                            <BoxedWidget
                                title={react_intl.formatMessage({id: "client.order_single.payment_records"})}
                                content={<Table className={"text-center"}>
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage id={"client.order_single.id"}/></th>
                                        <th><FormattedMessage id={"client.order_single.date"}/></th>
                                        <th><FormattedMessage id={"client.order_single.payment_method"}/></th>
                                        <th><FormattedMessage id={"client.order_single.installment_on_table"}/></th>
                                        <th><FormattedMessage id={"client.order_single.amount_on_table"}/></th>
                                        <th><FormattedMessage id={"client.order_single.receipt"}/></th>
                                    </tr>
                                    </thead>
                                    <tbody>{
                                        this.state.payments.map(item => {
                                            let data = item.attributes
                                            let payment_method = data.payment_method
                                            let method_class = payment_method.class
                                            let bObject = getBankObject(method_class)

                                            return <tr>
                                                <th>#{data.id}</th>
                                                <td>{moment(data.date).format(datetime_format)}</td>
                                                <td>
                                                    <div style={{
                                                        width: "110px",
                                                        height: 25,
                                                        background: `url('${(bObject.image || {}).default}') no-repeat center`,
                                                        backgroundSize: "50%"
                                                    }} alt=""></div>
                                                    {data.payment_method.name}
                                                </td>
                                                <td>{data.installment > 1 ? `${data.installment} ${(react_intl.formatMessage({id: "client.order_single.installment"}))}` : (react_intl.formatMessage({id: "client.order_single.cash"}))}</td>
                                                <td><ShowPrice
                                                    currencysuffix={` ${data.currency_code}`}
                                                    amount={data.total_amount}
                                                /></td>
                                                <td>
                                                    <TransactionPdfButton id={data.id}/>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </Table>}
                            /> : ""}
                </Col>
                <Col md={4} className={this.token ? "d-none" : ""}>
                    {!this.token && <BoxedWidget
                        title={react_intl.formatMessage({id: "client.order_single.invoice_info"})}
                        back={true}
                        style={{zIndex: 1}}
                        content={<>
                            <InvoiceDetails
                                details={orderDetails.invoice_details}
                                canChange={orderDetails.status === "unpaid"}
                                defaultValue={orderDetails.invoice_details}
                                onChange={(val) => {
                                    let clear = typeof val.value === "undefined"
                                    OrderModel.modify(this.orderId, {
                                        address_id: !clear ? val.value : ""
                                    })
                                        .then(r => r.json())
                                        .then(r => {
                                            this.setState({
                                                invoice_details_selected: typeof val.details === "object" ? val.details : null
                                            })
                                            openSnackbar(<FormattedMessage
                                                id={"client.order_single.updated_invoice_info"}/>)
                                        }).catch(r => ApiErrorThrow(r, openSnackbar))
                                }}
                            />
                        </>}
                    />}
                    <BoxedWidget
                        back={true}
                        title={react_intl.formatMessage({id: "client.order_single.invoice_example"})}
                        content={<div className={"text-center"}>
                            <OrderPdfButton
                                className={""}
                                buttontext={<>
                                    <FormattedMessage id={"client.order_single.download_invoice_example"}/> <i
                                    className={"icon-feather-download"}/>
                                </>}
                                size={"md"}
                                id={orderDetails.id}
                            />
                        </div>}
                    />
                    {orderDetails.status === "unpaid" && <BoxedWidget
                        title={react_intl.formatMessage({id: "client.order_single.payment_operations"})}
                        content={<div className={"text-center"}>
                            <Link className={"btn btn-dark"}
                                  id={orderDetails.id}
                                  to={`/cp/orders/${orderDetails.id}/pay`}
                            >
                                <FormattedMessage id={"client.order_single.pay"}/> <i
                                className={"icon-feather-arrow-right"}/>
                            </Link>
                        </div>}
                    />}
                </Col>
            </Row>
        </div>;
    }
}

export default withSnackbar(OrderSingle, defaultSnackbarOptions)