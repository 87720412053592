import React from "react";
import {Alert} from "@material-ui/lab";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import {Animated} from "react-animated-css";
import {BillingProfileLabels} from "../../../../Conts";
import BillingProfileForm from "../../../../Elements/BillingProfileForm";
import AddressModel from "../../../../Models/AddressModel";
import {withSnackbar} from "react-simple-snackbar";
import {defaultSnackbarOptions} from "../../../../Config";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../../index";

export const defaultAddressFields = {
    "address_type": "billing",
    "company_title": "",
    "title": "",
    "email": "",
    "phone": "", "tax_number": "", "tax_office": "",
    "address1": "", "address2": "", "state": "", "city": "", "postal_code": "",
    "country": "tr", "corporate": false, "no_citizenship": false, "is_default": true
}

export class EmptyBilling extends React.Component {
    render() {
        return <div>
            <div className={"text-center text-info bg-light pt-5 rounded pb-5"}>
                <Animated animationIn="pulse" isVisible={true}>
                    <i style={{fontSize: "40px"}} className={"icon-line-awesome-info-circle"}/>
                </Animated>
                <p><FormattedMessage id={"admin.clients.profile_billing_widget.no_info"} /></p>
            </div>
            <div className={"text-center"}>
                <Button
                    onClick={this.props.create}
                    color={"primary"}
                    className={"button-sliding-icon m-2"} size={"sm"}><FormattedMessage id={"admin.clients.profile_billing_widget.create"} /> <i
                    className={"icon-material-outline-add"}/></Button>
            </div>
        </div>;
    }
}

class ClientProfileBillingProfile extends React.Component {
    maxLength = 25;
    state = {
        modalOpened: false,
        information: {}
    }


    constructor(props) {
        super(props);
    }

    save = () => {
        let {openSnackbar} = this.props
        let save_data = {...this.props.addresses_modify}
        let filter_data = Object.keys(defaultAddressFields)
        let asArray = Object.entries(save_data);
        let filtered = asArray.filter(([key, value]) => filter_data.indexOf(key) > -1);
        save_data = Object.fromEntries(filtered);

        if (typeof save_data.city !== "undefined")
            save_data.city = save_data.city
        if (typeof save_data.state !== "undefined")
            save_data.state = save_data.state

        const callback = (r) => {
            if (typeof r.status === "boolean" && !r.status) {
                openSnackbar(r.message ? react_intl.formatMessage({id:"admin.clients.profile_billing_widget.error"},{message: r.message}) : react_intl.formatMessage({id:"admin.clients.profile_billing_widget.error_unknown"}))
                return false;
            }
            openSnackbar(react_intl.formatMessage({id:"admin.clients.profile_billing_widget.saved"}));
            if (typeof this.props.edit === "function")
                this.props.edit(defaultAddressFields)

            if (typeof this.props.onSave === "function")
                this.props.onSave()
        }

        save_data = Object.assign(defaultAddressFields, save_data)
        if (this.props.address_id) {
            AddressModel.modify(this.props.client.id, this.props.address_id, save_data).then(r => r.json())
                .then(callback)
        } else {
            AddressModel.create(this.props.client.id, save_data).then(r => r.json())
                .then(callback)
        }
    }

    render() {
        let {addresses} = this.props
        if (typeof addresses === "object")
            addresses = addresses.attributes
        else
            addresses = defaultAddressFields

        return <>
            {<div className="information-table-div p-1">
                <table className={"table table-striped mb-0"} width={"100%"} id={"cp-billing-information-table"}>
                    <tbody>
                    <tr>
                        <td className={"p-0"}>
                            <div>
                                <Row className={"pt-1"}>
                                    <Col>
                                        <h5 className={"pl-2"}>{addresses.title}</h5>
                                    </Col>
                                    <Col className={"text-right pr-4"}>
                                        <Button
                                            onClick={() => this.props.deleteToggle(this.props.address_id)}
                                            color={"danger"}
                                            className={"rounded-circle ripple-effect text-center p-0"}
                                            style={{width: 24, height: 24}}><i
                                            className={" icon-material-outline-delete"}/></Button>
                                        {" "}
                                        {" "}
                                        <Button
                                            onClick={() => {
                                                this.props.toggle(addresses)
                                            }}
                                            color={"dark"}
                                            className={"rounded-circle ripple-effect text-center p-0"}
                                            style={{width: 24, height: 24}}><i
                                            className={"icon-feather-zoom-in"}/></Button>
                                        {" "}
                                        {" "}
                                        <Button onClick={() => {
                                            this.props.edit(addresses)
                                        }}
                                                color={"primary"}
                                                className={"rounded-circle ripple-effect text-center p-0"}
                                                style={{width: 24, height: 24}}><i
                                            className={"icon-feather-edit"}/></Button>
                                    </Col>
                                </Row>
                                <Table className={"mb-0"}>
                                    <tbody>
                                    <tr>
                                        <th><FormattedMessage id={"admin.clients.profile_billing_widget.company_name"} /></th>
                                        <td>{(addresses.company_title).length > this.maxLength ? (addresses.company_title).substr(0, this.maxLength - 3) + '...' : addresses.company_title}</td>
                                    </tr>
                                    <tr>
                                        <th><FormattedMessage id={"admin.clients.profile_billing_widget.tax_number"} /></th>
                                        <td>{addresses.tax_number}</td>
                                    </tr>
                                    <tr>
                                        <th><FormattedMessage id={"admin.clients.profile_billing_widget.city_state"} /></th>
                                        <td>{`${addresses.city} / ${addresses.state}`}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>}
        </>;
    }
}

export default withSnackbar(ClientProfileBillingProfile, defaultSnackbarOptions)