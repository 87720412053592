import React from "react"
import {Button, Col, Row, Table} from "reactstrap";
import InputWithIcon from "../../../Elements/InputWithIcon";
import CurrencyModel from "../../../Models/CurrencyModel";
import GlSelect from "../../../Components/GlSelect";
import NumberFormat from "react-number-format";
import Switch from "@material-ui/core/Switch";
import TransactionModel from "../../../Models/TransactionModel";
import PaymentMethodSelect from "../../../Elements/PaymentMethodSelect";
import moment from "moment";
import {ApiErrorThrow, get_default_currency} from "../../../Config";
import {Select38Px} from "../../../Conts";
import {FormattedMessage} from "react-intl";
import {react_intl} from "../../../index";
import classnames from "classnames";

class AddTransactionTab extends React.Component {
    state = {
        isRefund: (this.props.defaultValues && this.props.defaultValues.total_amount < 0) || false,
        transaction_id: this.props.defaultValues ? this.props.defaultValues.transaction_id : "",
        total_amount: this.props.defaultValues ? this.props.defaultValues.total_amount : 0,
        fee: this.props.defaultValues ? this.props.defaultValues.fee : 0,
        payment_method: this.props.defaultValues ? this.props.defaultValues.payment_method : {},
        currency_code: this.props.defaultValues.currency_code ? this.props.defaultValues.currency_code : "",
        currency_rate: this.props.defaultValues.currency_rate ? this.props.defaultValues.currency_rate : 1,
        notes: this.props.defaultValues ? this.props.defaultValues.notes : ""
    }

    currency = null

    inputHandle = (e) => {
        let stateObject = {}
        stateObject[e.target.name] = e.target.value
        this.setState(stateObject)
    }

    constructor(props) {
        super(props);
        this.refundToggle = this.refundToggle.bind(this)
    }

    refundToggle = () => {
        this.setState({isRefund: !this.state.isRefund})
    }

    process = (editId, callback) => {
        let {openSnackbar} = this.props
        let stateCopy = Object.assign({}, this.state), action
        stateCopy.order_id = this.props.orderDetails.id
        stateCopy.status = 1
        stateCopy.date = moment().format()
        stateCopy.oid = ""
        stateCopy.currency_rate = this.state.currency_rate

        if (stateCopy.isRefund || (!stateCopy.isRefund && stateCopy.total_amount < 0))
            stateCopy.total_amount *= -1

        delete stateCopy.isRefund


        if (typeof stateCopy.payment_method === "object")
            stateCopy.payment_method = stateCopy.payment_method.id
        if (typeof stateCopy.currency === "object")
            stateCopy.currency = stateCopy.currency.id

        if (typeof stateCopy.id !== "undefined")
            delete stateCopy.id
        if (typeof stateCopy.updated !== "undefined")
            delete stateCopy.updated


        if (editId)
            action = TransactionModel.modify(editId, stateCopy)
        else
            action = TransactionModel.create(this.props.orderId, stateCopy)


        action.then(r => r.json())
            .then(r => {
                if (typeof r.errors === "object") {
                    alert(react_intl.formatMessage({id: "admin.orders.add_transaction_tab.some_errors"}, {
                        message: r.errors.join(" - ")
                    }))
                    return;
                }
                if (typeof r.status === "boolean" && !r.status) {
                    alert(r.message)
                    return;
                }
                if (typeof this.props.toggle === "function")
                    this.props.toggle()
                if (typeof this.props.reload === "function") {
                    this.props.reload()
                }

                if (typeof callback == "function")
                    callback()

            }).catch(r => {
            ApiErrorThrow(r, openSnackbar)
            if (typeof callback === "function")
                callback()
        })
    }

    render() {
        let {currency_code} = this.state
        let {orderDetails, defaultValues} = this.props

        if (typeof defaultValues !== "object" || defaultValues === null)
            defaultValues = false

        if (typeof defaultValues.total_amount === "string" && defaultValues.total_amount !== "")
            defaultValues.total_amount = parseFloat(defaultValues.total_amount)

        return <div>
            <Table className={"add-transaction-table rounded mt-3 mb-3 d-inline-block border-0"} bordered={0}>
                <tbody className={"float-left w-100"}>
                <tr className={"position-relative"} style={{zIndex:99}}>
                    <th>
                        <FormattedMessage id={"admin.orders.add_transaction_tab.gateway"}/>
                    </th>
                    <td>
                        <PaymentMethodSelect
                            isMulti={false}
                            defaultValue={typeof defaultValues.payment_method === "object" ? {
                                label: defaultValues.payment_method.name,
                                value: defaultValues.payment_method.id
                            } : null}
                            onChange={async (value) => {
                                this.inputHandle({
                                    target: {
                                        name: "payment_method",
                                        value: value.value
                                    }
                                })
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage id={"admin.orders.add_transaction_tab.transaction_id"}/>
                    </th>
                    <td>
                        <InputWithIcon
                            icon={"icon-feather-hash"} name={"transaction_id"}
                            defaultValue={defaultValues.transaction_id}
                            onChange={(e) => {
                                this.inputHandle(e)
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage id={"admin.orders.add_transaction_tab.amount"}/>
                    </th>
                    <td>
                        <InputWithIcon
                            input_type={"number"}
                            defaultValue={(typeof defaultValues.total_amount === "number") ? (defaultValues.total_amount < 0 ? defaultValues.total_amount * -1 : defaultValues.total_amount) : ""}
                            onValueChange={(values) => {
                                this.inputHandle({
                                    target: {
                                        name: "total_amount",
                                        value: typeof values.floatValue === "number" ? values.floatValue.toFixed(2) : (0).toFixed(2)
                                    }
                                })
                            }}
                            currency={this.currency}
                        />
                    </td>
                </tr>
                <tr>
                    <th><FormattedMessage id={"admin.orders.add_transaction_tab.fee"}/></th>
                    <td>
                        <InputWithIcon
                            value={defaultValues.fee}
                            input_type={"number"}
                            onValueChange={(values) => {
                                this.inputHandle({
                                    target: {
                                        name: "fee",
                                        value: values.floatValue
                                    }
                                })
                            }}
                            currency={this.currency}
                        />
                    </td>
                </tr>
                <tr style={{zIndex:98}} className={"position-relative"}>
                    <th><FormattedMessage id={"admin.orders.add_transaction_tab.currency"}/></th>
                    <td>
                        {defaultValues.currency ? <GlSelect
                            closeMenuOnSelect={true}
                            url={'/currency'}
                            firstSelected={true}
                            placeholder={react_intl.formatMessage({id: "admin.orders.add_transaction_tab.currency_placeholder"})}
                            defaultValue={defaultValues ? {
                                value: defaultValues.currency.id,
                                label: defaultValues.currency.code
                            } : null}
                            onChange={async (value) => {
                                await CurrencyModel.getByCode(value.value).then(r => r.json())
                                    .then(item => {
                                        item = item.data.attributes
                                        this.currency = {
                                            prefix: item.prefix,
                                            suffix: item.suffix,
                                            decimal: item.currency_decimal
                                        }
                                    })

                                this.inputHandle({
                                    target: {
                                        name: "currency_code",
                                        value: value.value
                                    }
                                })
                            }}
                            styles={Select38Px}
                        /> : "-"}
                    </td>
                </tr>
                <tr className={classnames({
                    "d-none": currency_code === get_default_currency("code")
                })}>
                    <th><FormattedMessage id={"admin.orders.add_transaction_tab.currency_rate"}/></th>
                    <td>
                        <InputWithIcon
                            input_type={"number"}
                            currency={{}}
                            defaultValue={(typeof defaultValues.currency_rate === "number") ? (defaultValues.currency_rate) : ""}
                            onValueChange={(values) => {
                                this.inputHandle({
                                    target: {
                                        name: "currency_rate",
                                        value: typeof values.floatValue === "number" ? values.floatValue.toFixed(2) : (0).toFixed(2)
                                    }
                                })
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <th><FormattedMessage id={"admin.orders.add_transaction_tab.installment"}/></th>
                    <td>
                        <InputWithIcon type={"number"} defaultValue={defaultValues.installment} onChange={(e) => {
                            this.inputHandle({
                                target: {
                                    name: "installment",
                                    value: e.target.value
                                }
                            })
                        }}/>
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage id={"admin.orders.add_transaction_tab.note"}/>
                    </th>
                    <td>
                        <textarea name={"notes"} onChange={this.inputHandle}
                                  defaultValue={defaultValues.notes}
                                  className={"form-control"}></textarea>
                    </td>
                </tr>
                </tbody>
            </Table>
        </div>;
    }
}

export default AddTransactionTab