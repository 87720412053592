import GlRequest from "../Lib/GlRequest";

class OrderModel {
    createAnonymous = (details) => {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/order', details, 'json')
    }
    create = (details) => {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.post('/api/v2/order', details, 'json')
    }

    getById = (orderId, include) => {
        let url = ""
        url = '/api/v2/order/' + orderId
        if (typeof include === "object" && include.length)
            url += `?include=${include.join(",")}`
        return GlRequest.get(url)
    }

    getByIdWithToken = (orderId, token, include) => {
        let url = ""
        url = '/order/' + token
        if (typeof include === "object" && include.length)
            url += `?include=${include.join(",")}`
        return GlRequest.get(url)
    }

    modify(orderId, details) {
        details = {
            data: {
                attributes: details
            }
        }
        return GlRequest.patch('/api/v2/order/' + orderId, details, 'json')
    }

    delete(orderId) {
        return GlRequest.delete('/api/v2/order/' + orderId)
    }

    duplicate(orderId) {
        return GlRequest.get(`/api/v2/order/${orderId}/duplicate`)
    }
}

export default OrderModel = new OrderModel()